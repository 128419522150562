<template>
    <div>
        <div>
            <v-alert
                :value="!paymentMethodValid && addAccountDialogError"
                class="mb-5">
                You need to complete the ad account payment process
                to fulfill this step. Please click "Add Payment Method"
                below or add payment details directly into ads manager
            </v-alert>

            <v-alert
                :value="errors.length"
                class="mb-5">
                <p>
                    There were errors while processing. Please try again or contact support if
                    the problems continue.
                </p>
                <ul>
                    <li
                        v-for="error in errors"
                        :key="error">
                        {{ error }}
                    </li>
                </ul>
            </v-alert>

            <styled-slideout v-model="paymentMethodSlideoutActive">
                <div class="pa-5">
                    <h2>Adding an Ad Account Payment Method</h2>
                    <p>
                        Snapchat ads ain't free! Snapchat requires a payment method
                        before any ads can run.
                    </p>
                    <p>
                        You can use the "Add Payment Method"
                        button here to enter credit card details directly into Snapchat
                        or you (or someone else) can add payment details to the account
                        directly at the link below:
                    </p>
                    <p>
                        <a
                            :href="paymentSettingsUrl"
                            target="_blank">Payment Methods &amp; Settings</a>
                        <action-button
                            v-clipboard:copy="paymentSettingsUrl"
                            v-clipboard:success="onCopy"
                            icon="copy" />
                    </p>
                    <p>
                        <em>Note that if you're entering payment details manually
                            you'll need to click "recheck status" after completing the
                            process so BuyerBridge can validate that the process is complete</em>
                    </p>
                </div>
            </styled-slideout>

            <h2>Ad Account Configuration</h2>
            <p class="mb-4">
                Your ad account is now powered by BuyerBridge 🔥!
                Please take action on the items below to ensure
                it's configured properly for this {{ onboardingType }}:
            </p>

            <div class="panel">
                <div
                    v-if="requiresPaymentMethod"
                    class="layout wrap align-center mb-4">
                    <div class="flex xs12 md6 lg7 layout align-center">
                        <status-icon
                            :value="paymentMethodValid ? 'success' : 'warning'"
                            size="25" />
                        <span class="ml-3">
                            {{ paymentMethodValid ? 'Configured' : 'Configure' }} a payment method for this ad account
                        </span>
                    </div>
                    <div class="flex xs12 md6 lg5 layout align-center">
                        <div class="flex grow">
                            <styled-tooltip
                                :disabled="paymentMethodTooltipDisabled">
                                <styled-button
                                    :disabled="paymentMethodValid"
                                    gray
                                    outline
                                    full-width
                                    class="text-uppercase"
                                    @click="addPaymentMethod">
                                    Add Payment Method
                                </styled-button>
                                <template #content>
                                    Your Snapchat account needs to have access to
                                    {{ currentDealer.agency.name }}'s business manager so we
                                    can automatically add you to {{ currentDealer.name }}'s
                                    ad account add a payment method.
                                </template>
                            </styled-tooltip>
                        </div>
                        <div class="flex shrink ml-2">
                            <action-button
                                icon="question-circle"
                                size="18"
                                @click="paymentMethodSlideoutActive = !paymentMethodSlideoutActive" />
                        </div>
                    </div>
                </div>

                <div class="text-xs-right mt-3">
                    <action-button
                        icon="refresh"
                        position="left"
                        size="15"
                        :loading="onboardingStatusLoading"
                        @click="onRecheckOnboardingStatus">
                        {{ onboardingStatusLoading ? 'Rechecking status' : 'Recheck status' }}
                    </action-button>
                </div>
            </div>
        </div>

        <div class="layout align-center">
            <back-button
                @click="$store.dispatch('goToPreviousStep')" />
            <a
                href="#"
                class="ml-auto d-inline-block mr-3"
                @click.prevent="$store.dispatch('handleSkipStepRequest')">
                skip &amp; finish later
            </a>
            <styled-button
                :disabled="!valid"
                @click="$store.dispatch('goToNextStep')">
                CONTINUE
            </styled-button>
        </div>
    </div>
</template>

<script>
import BackButton from '@/components/globals/BackButton';
import StatusIcon from '@/components/globals/StatusIcon';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledButton from '@/components/globals/StyledButton';
import ActionButton from '@/components/globals/ActionButton';
import StyledTooltip from '@/components/globals/StyledTooltip';
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'OnboardingAdAccountDependencies',
    components: {
        BackButton,
        StyledButton,
        StatusIcon,
        StyledTooltip,
        ActionButton,
        StyledSlideout
    },
    data() {
        return {
            addAccountDialogError: false,
            userInAdAccount: false,
            errors: [],
            onboardingStatusLoading: false,
            paymentMethodDetectionInterval: null,
            paymentMethodDetectionTimeout: false,
            paymentMethodDetectionTries: 0,
            paymentMethodSlideoutActive: false
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            currentDealer: (state) => state.dealer.currentDealer,
            onboardingType: (state) => state.onboarding.type,
            onboardingData: (state) => state.onboarding.data
        }),
        ...mapGetters([
            'dealerSnapchatAdAccountUrl',
            'dealerSnapchatAdAccountId',
            'dealerSnapchatOrganizationId',
            'requiredFeatures',
            'incompleteFeatures',
        ]),
        requiresPaymentMethod() {
            return this.requiredFeatures.includes('snapchat_ad_account');
        },
        paymentMethodValid() {
            return !this.incompleteFeatures.includes('snapchat_ad_account');
        },
        paymentMethodDisabled() {
            if (this.paymentMethodValid) {
                return true;
            }

            return !this.userInAdAccount;
        },
        paymentMethodTooltipDisabled() {
            if (this.paymentMethodValid) {
                return true;
            }

            return this.userInAdAccount;
        },
        paymentSettingsUrl() {
            return `https://business.snapchat.com/${this.dealerSnapchatAdAccountId}/settings/payment-methods/create`;
        },
        outstandingFeatures() {
            const applicableFeatures = [
                'snapchat_ad_account',
            ];

            return applicableFeatures.filter(feature => this.incompleteFeatures.includes(feature));
        },
        valid() {
            return (
                (this.requiresPaymentMethod ? this.paymentMethodValid : true)
            );
        },
    },
    async mounted() {
        await this.$store.dispatch('waitForDealerLoaded');

        await this.$store.dispatch('updateDealerFeatureStatus', {
            features: this.outstandingFeatures
        });
    },
    destroyed() {
        clearInterval(this.paymentMethodDetectionInterval);
    },
    methods: {
        async onRecheckOnboardingStatus() {
            this.onboardingStatusLoading = true;
            await this.$store.dispatch('updateDealerFeatureStatus', {
                features: this.outstandingFeatures
            });
            this.onboardingStatusLoading = false;
        },
        onCopy() {
            this.$flash('URL successfully copied to your clipboard', 'green');
        },
        addPaymentMethod() {
            return window.open(this.paymentSettingsUrl);
        }
    }
};
</script>

<style lang="scss" scoped>
.panel {
    margin: 60px 40px;
    height: 100%;

    @media (min-width: $bp-lg) {
        margin: 60px 100px;
    }
}
</style>
